import {
  BellRinging,
  Fingerprint,
  Key,
  Settings,
  TwoFA,
  DatabaseImport,
  Receipt2,
} from "tabler-icons-react";

const data = [
  { link: "/app/dashboard", label: "Dashboard", icon: BellRinging },
  {
    link: "/app/contribution-plan",
    label: "Contribution Plans",
    icon: Receipt2,
  },
  { link: "/app/marketplace", label: "Marketplace", icon: Fingerprint },
  { link: "", label: "SSH Keys", icon: Key },
  { link: "", label: "Databases", icon: DatabaseImport },
  { link: "", label: "Authentication", icon: TwoFA },
  { link: "", label: "Other Settings", icon: Settings },
];

export default data;
