import React from "react";
import { Button, Input, RangeSlider, Switch, Group } from "@mantine/core";

import { Formik, Field, Form } from "formik";
import RecordSchema from "./record-schema";
import { DisplayFormikState } from "components/common/DisplayFormikState";

// Input feedback
const InputFeedback = ({ error }) => (error ? <div>{error}</div> : null);

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children,
}) => {
  return (
    <Group>
      <div htmlFor="payrollFrequency">{label}</div>
      <div className="d-block">
        {children}
        {touched && <InputFeedback error={error} />}
      </div>
    </Group>
  );
};

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <Switch
      name={name}
      id={id}
      type="radio"
      value={id}
      checked={id === value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

class ContributionsForm extends React.Component {
  render() {
    let { initialValues, onSubmit } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={RecordSchema}
        render={({ values, handleSubmit, errors, touched }) => (
          <React.Fragment>
            <Form className="m-3" onSubmit={handleSubmit}>
              <RadioButtonGroup
                id="payrollFrequency"
                label="Payroll Frequency "
                value={values.payrollFrequency}
                error={errors.payrollFrequency}
                touched={touched.payrollFrequency}
              >
                <Field
                  component={RadioButton}
                  name="payrollFrequency"
                  id="monthly"
                  label="Monthly"
                />
                <Field
                  component={RadioButton}
                  name="payrollFrequency"
                  id="semi-monthly"
                  label="Semi-Monthly"
                />
              </RadioButtonGroup>

              <Group>
                <div htmlFor="over50">Catch-up Contributions Eligibility</div>
                <Field name="over50">
                  {({ field, onChange }) => (
                    <Switch
                      type="switch"
                      id="over50"
                      name="over50"
                      label="Over 50 Years Old"
                      {...field}
                    />
                  )}
                </Field>
              </Group>
              <Group>
                <div htmlFor="grossPay">
                  Annual Pay (Range From $0 To $400,000)
                </div>
                <Field name="grossPay">
                  {({ field, onChange }) => (
                    <RangeSlider
                      type="range"
                      id="grossPay"
                      name="grossPay"
                      {...field}
                      min={0}
                      max={400000}
                    />
                  )}
                </Field>

                <Input
                  type="number"
                  name="grossPay"
                  placeholder="Gross Pay Per Period"
                  value={values["grossPay"]}
                  onChange={() => {}}
                />
              </Group>

              <Group>
                <div htmlFor="contributionPercentage">
                  Employee Contribution (Range From 0% To 100%)
                </div>
                <Field name="contributionPercentage">
                  {({ field }) => (
                    <RangeSlider
                      type="range"
                      id="contributionPercentage"
                      name="contributionPercentage"
                      {...field}
                      min={0}
                      max={100}
                    />
                  )}
                </Field>
                <Input
                  type="number"
                  name="contributionPercentage"
                  value={values["contributionPercentage"]}
                  onChange={() => {}}
                />
              </Group>

              <Group>
                <div htmlFor="matchingLimit">
                  Employer Match Up To (%) Of Compensation (Range From 0% To
                  30%)
                </div>
                <Field name="matchingLimit">
                  {({ field }) => (
                    <RangeSlider
                      type="range"
                      id="matchingLimit"
                      name="matchingLimit"
                      {...field}
                      min={0}
                      max={30}
                    />
                  )}
                </Field>
                <Input
                  type="number"
                  name="matchingLimit"
                  value={values["matchingLimit"]}
                  onChange={() => {}}
                />
              </Group>

              <Group>
                <div htmlFor="matchingPercentage">
                  Employer Match (Range From 0% To 100%)
                </div>
                <Field name="matchingPercentage">
                  {({ field }) => (
                    <RangeSlider
                      type="range"
                      id="matchingPercentage"
                      name="matchingPercentage"
                      {...field}
                      min={0}
                      max={100}
                    />
                  )}
                </Field>
                <Input
                  type="number"
                  name="matchingPercentage"
                  value={values["matchingPercentage"]}
                  onChange={() => {}}
                />
              </Group>

              <Button type="submit">Submit</Button>
            </Form>
            {false && <DisplayFormikState values={values} />}
          </React.Fragment>
        )}
      />
    );
  }
}

export default ContributionsForm;
