const apiEndpoints = [
  {
    name: "FLEXI_SUBSCRIPTIONS_API",
    endpoint: "https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod",
    //endpoint: 'https://api.flexi.network/subscriptions',
    region: "us-east-1",
  },
  {
    name: "FLEXI_USERS_API",
    endpoint: "https://gai5mnzym3.execute-api.us-east-1.amazonaws.com/Prod",
    region: "us-east-1",
  },
  {
    name: "FlexiBillingApi",
    endpoint: "https://api.flexi.network/ops-api",
    region: "us-east-1",
  },
];

export default {
  endpoints: apiEndpoints,
};
