import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button } from "@mantine/core";

export const SignOutButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate("/signout");
      }}
    >
      Sign Out
    </Button>
  );
};

SignOutButton.propTypes = {
  className: PropTypes.string,
};
