import React from "react";

import { SignInButton } from "modules/users/components/SignInButton";
import { SignOutButton } from "modules/users/components/SignOutButton";

import { Group, Box, Header, Avatar } from "@mantine/core";
import { NavLink } from "react-router-dom";

import { useAuth } from "auth/AuthContext";

const defaultPhotoUrl =
  "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp";

const UserName = ({ user }) => {
  const {
    attributes: { given_name, family_name, email },
  } = user;
  return (
    <Group>
      {given_name} {family_name}
    </Group>
  );
};

const UserAvatar = ({ user }) => {
  const {
    attributes: { given_name, family_name, photo },
  } = user;
  return (
    <Avatar
      src={photo ?? defaultPhotoUrl}
      alt={`${given_name} ${family_name}`}
      radius="lg"
    />
  );
};

export function AppHeader() {
  const { user } = useAuth();

  return (
    <Header p="xs">
      <Box
        sx={(theme) => ({
          paddingLeft: theme.spacing.xs,
          paddingRight: theme.spacing.xs,
          paddingBottom: theme.spacing.lg,
        })}
      >
        <Group position="apart">
          <NavLink to="/">
            <img
              src="https://s3.amazonaws.com/cdn.acctbyte.com/images/logo/acctbyte_logo.png"
              alt="logo"
            />
          </NavLink>

          {user ? (
            <Group>
              {" "}
              <UserAvatar user={user} /> <UserName user={user} />
              <SignOutButton />{" "}
            </Group>
          ) : (
            <SignInButton />
          )}
        </Group>
      </Box>
    </Header>
  );
}
