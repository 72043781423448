import API from "./api";
import Analytics from "./analytics";
import Auth from "./auth";

export default {
  Auth: Auth,
  API: API,
  Analytics: Analytics,
  LogoUrl:
    "https://s3.amazonaws.com/cdn.acctbyte.com/images/logo/acctbyte_logo.png",
  FaviconUrl:
    "https://s3.amazonaws.com/cdn.acctbyte.com/images/favicon/favicon.png",
};
