import React from "react";
import Amplify, { Analytics } from "aws-amplify";

// get config
import config from "config";
Amplify.configure(config);
//Amplify.Logger.LOG_LEVEL = 'INFO';
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "pageView",

  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  // attributes: {
  //     attr: 'attr'
  // },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },

  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "SPA",

  // OPTIONAL, the service provider, by default is the AWS Pinpoint
  provider: "AWSPinpoint",

  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});
