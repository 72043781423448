import React from "react";
import PropTypes from "prop-types";

import { Button, Input } from "@mantine/core";

class MarketplacePage extends React.Component {
  render() {
    return (
      <form
        method="post"
        action="https://ofyhixe0dg.execute-api.us-east-1.amazonaws.com/Prod/marketplace"
        target="_blank"
      >
        <div>
          <h3>Sample Marketplace Token</h3>
          <Input type="text" name="x-amzn-marketplace-token" />
        </div>

        <div>
          <Button type={"submit"}>Submit</Button>
        </div>
      </form>
    );
  }
}

export default MarketplacePage;
