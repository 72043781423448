import { BellRinging, Fingerprint, Key, Settings } from "tabler-icons-react";

const data = [
  {
    link: "#",
    label: "Contact",
    icon: BellRinging,
  },
  {
    link: "#",
    label: "Privacy",
    icon: Fingerprint,
  },
  {
    link: "#",
    label: "Blog",
    icon: Key,
  },
  {
    link: "#",
    label: "Careers",
    icon: Settings,
  },
];

export default data;
