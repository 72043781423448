import React from "react";
import PropTypes from "prop-types";

import { Table } from "@mantine/core";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const ContributionLimitsTable = ({ items }) => {
  const columns = [
    {
      name: "Annual Contribution Limit",
      key: "contributionLimit",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Catch-up Contribution Limit",
      key: "catchupContributionLimit",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Total Contribution Limit",
      key: "totalContributionLimit",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
  ];

  const rows = items.map((element, index) => (
    <tr key={index}>
      <td>{element.contributionLimit}</td>
      <td>{element.catchupContributionLimit}</td>
      <td>{element.totalContributionLimit}</td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column) => {
            return <th key={column.key}>{column.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

ContributionLimitsTable.propTypes = {
  items: PropTypes.array,
};

ContributionLimitsTable.defaultProps = {
  items: [],
};
