import React from "react";
import PropTypes from "prop-types";

import { Container, Center } from "@mantine/core";
import { NavLink, useNavigate } from "react-router-dom";

import { SignInButton } from "modules/users/components/SignInButton";

import { useAuth } from "auth/AuthContext";

import { AppHeader } from "components/page/AppHeader.js";
import { FooterSimple } from "components/page/FooterSimple";
import { AppShell, Navbar, Header } from "@mantine/core";

import { HeroBullets } from "../components/HeroBullets";

import links from "config/footer-links";

export const PublicHomePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user) {
    navigate("/app/dashboard");
  }

  return (
    <AppShell
      padding="md"
      header={<AppHeader />}
      footer={<FooterSimple links={links} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <HeroBullets />
    </AppShell>
  );
};
