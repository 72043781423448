import React from "react";
import PropTypes from "prop-types";

import { Table } from "@mantine/core";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const TotalsTable = ({ items }) => {
  const columns = [
    {
      name: "Annual Employee Contribution",
      key: "annualEmployeeContributionAmount",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Annual Employer Matching",
      key: "annualEmployerMatchingAmount",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Total Annual Retirement Contributions",
      key: "annualTotalRetirementContributionsAmount",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
  ];

  const rows = items.map((element, index) => (
    <tr key={index}>
      <td>{element.annualEmployeeContributionAmount}</td>
      <td>{element.annualEmployerMatchingAmount}</td>
      <td>{element.annualTotalRetirementContributionsAmount}</td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column) => {
            return <th key={column.key}>{column.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

TotalsTable.propTypes = {
  items: PropTypes.array,
};

TotalsTable.defaultProps = {
  items: [],
};
