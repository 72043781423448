import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setUser(cognitoUser);
      })
      .catch((error) => {
        console.trace("AUTH CONTEXT. FETCH COGNITO USER ERROR", error);

        Auth.currentSession()
          .then((data) => {
            console.debug("AUTH CONTEXT. CALL CURRENT SESSION", data);
          })
          .catch((err) => {
            console.trace("AUTH CONTEXT. CALL CURRENT SESSION ERROR", err);
          });
      });
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthProvider.propTypes = {
  children: PropTypes.object,
};
