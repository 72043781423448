import React from "react";
import PropTypes from "prop-types";

import { Container } from "@mantine/core";
import moment from "moment";

import { ContributionsTable } from "../components/ContributionsTable";
import { ContributionLimitsTable } from "../components/ContributionLimitsTable";
import { TotalsTable } from "../components/TotalsTable";

import ContributionItem from "../components/ContributionItem";

import ContributionsForm from "../forms/ContributionsForm";

class ContributionsPlanPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      limit: 100,
      annualContributionLimit: 19500,
      catchupContributionLimit: 6500,
      planYear: 2020,
      payrollFrequency: "monthly",
      over50: false,
      grossPay: 30000,
      contributionPercentage: 5,
      matchingLimit: 8,
      matchingPercentage: 50,
      contributions: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getLimits() {
    let { annualContributionLimit, catchupContributionLimit, over50 } =
      this.state;

    return [
      {
        contributionLimit: annualContributionLimit,
        catchupContributionLimit: over50 ? catchupContributionLimit : 0,
        totalContributionLimit: over50
          ? annualContributionLimit + catchupContributionLimit
          : annualContributionLimit,
      },
    ];
  }

  getTotalContributionLimit() {
    let { annualContributionLimit, catchupContributionLimit, over50 } =
      this.state;
    return over50
      ? annualContributionLimit + catchupContributionLimit
      : annualContributionLimit;
  }

  getSemiMonthlyPayrollPeriods(year) {
    let dates = [];

    let startDate = new Date(year, 0);

    let finalDate;
    for (var i = 0; i <= 11; i++) {
      finalDate = moment(startDate)
        .add(1 * i, "month")
        .add(14, "days");
      dates.push(new Date(finalDate.format()).toDateString());

      finalDate = moment(startDate).add(i, "month").endOf("month");
      dates.push(new Date(finalDate.format()).toDateString());
    }

    return dates;
  }

  getMonthlyPayrollPeriods(year) {
    let dates = [];

    let startDate = new Date(year, 0);

    let finalDate;
    for (var i = 0; i <= 11; i++) {
      finalDate = moment(startDate).add(i, "month").endOf("month");
      dates.push(new Date(finalDate.format()).toDateString());
    }
    return dates;
  }

  getItems() {
    let { contributions } = this.state;
    console.log(contributions);

    let items = this.getPayrollPeriods().map((period) => {
      let item = new ContributionItem({
        contributionLimitIsReached:
          contributions >= this.getTotalContributionLimit() ? true : false,
        //contributionLimitIsReached: false,
        payrollPeriod: period,
        grossPay: this.state.grossPay,
        contributionPercentage: this.state.contributionPercentage,
        matchingLimit: this.state.matchingLimit,
        matchingPercentage: this.state.matchingPercentage,
      });

      //this.setState({contributions: contributions + item.getContributionAmount()});
      return item.getItem();
    });

    return items;
  }

  getPayrollPeriods() {
    let { planYear, payrollFrequency } = this.state;

    let periods = [];
    switch (payrollFrequency) {
      case "monthly":
        periods = this.getMonthlyPayrollPeriods(planYear);
        break;
      case "semi-monthly":
        periods = this.getSemiMonthlyPayrollPeriods(planYear);
        break;
      default:
        periods = this.getSemiMonthlyPayrollPeriods(planYear);
        break;
    }

    return periods;
  }

  getPeriodsCount() {
    let { payrollFrequency } = this.state;

    let periodsCount = 12;
    switch (payrollFrequency) {
      case "monthly":
        periodsCount = 12;
        break;
      case "semi-monthly":
        periodsCount = 24;
        break;
    }

    return periodsCount;
  }

  getContributionPercentage() {
    let { contributionPercentage } = this.state;
    return contributionPercentage / 100;
  }

  getAnnualEmployeeContributionAmount() {
    let { grossPay } = this.state;
    return grossPay * this.getContributionPercentage();
  }

  getAnnualEmployerMatchingAmount() {
    let { grossPay } = this.state;
    return (
      (((grossPay * this.state.matchingLimit) / 100) *
        this.state.matchingPercentage) /
      100
    );
  }

  getAnnualTotalRetirementContributionsAmount() {
    return (
      this.getAnnualEmployeeContributionAmount() +
      this.getAnnualEmployerMatchingAmount()
    );
  }

  getTotals() {
    let { grossPay } = this.state;
    return [
      {
        annualEmployeeContributionAmount:
          this.getAnnualEmployeeContributionAmount(),
        annualEmployerMatchingAmount: this.getAnnualEmployerMatchingAmount(),
        annualTotalRetirementContributionsAmount:
          this.getAnnualTotalRetirementContributionsAmount(),
      },
    ];
  }

  handleSubmit(values, actions) {
    this.setState({
      payrollFrequency: values.payrollFrequency,
      over50: values.over50,
      grossPay: values.grossPay,
      contributionPercentage: values.contributionPercentage,
      matchingLimit: values.matchingLimit,
      matchingPercentage: values.matchingPercentage,
    });
  }

  render() {
    let {
      page,
      limit,
      payrollFrequency,
      over50,
      grossPay,
      contributionPercentage,
      matchingLimit,
      matchingPercentage,
    } = this.state;

    let initialValues = {
      payrollFrequency: payrollFrequency,
      over50: over50,
      grossPay: grossPay,
      contributionPercentage: contributionPercentage,
      matchingLimit: matchingLimit,
      matchingPercentage: matchingPercentage,
    };

    return (
      <Container fluid>
        <div>
          <div className="mb-3 p-3 text-center">
            <h1>401k Plan Contributions with Employer Matching</h1>
          </div>
        </div>
        <div>
          <div md="4">
            <h2>Inputs</h2>
            <ContributionsForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
            />
          </div>

          <div md="8">
            <h2>Limits</h2>
            <ContributionLimitsTable items={this.getLimits()} className="m-3" />
            <h2>Plan</h2>
            <ContributionsTable
              className="m-3"
              items={this.getItems()}
              page={page}
              limit={limit}
            />

            <h2>Totals</h2>
            <TotalsTable className="m-3" items={this.getTotals()} />
          </div>
        </div>
      </Container>
    );
  }
}

export default ContributionsPlanPage;
