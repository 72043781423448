import { useEffect } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export const SignOutPage = ({ navigateTo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        navigate(navigateTo);
      })
      .catch((err) => console.trace(err));
  }, [navigate, navigateTo]);

  return null;
};

SignOutPage.propTypes = {
  navigateTo: PropTypes.string.isRequired,
};

SignOutPage.defaultProps = {
  navigateTo: "",
};
