import React from "react";
import PropTypes from "prop-types";

import { Table } from "@mantine/core";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
});

export const ContributionsTable = ({ items, page, limit }) => {
  const columns = [
    {
      name: "Period",
      key: "payrollPeriod",
    },
    {
      name: "Gross Pay Per Period",
      key: "payPerPeriod",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Contribution Amount",
      key: "contribution",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
    {
      name: "Matching Limit (%)",
      key: "matchingLimit",
      formatter: ({ value }) => (
        <div className="text-center">{percentFormatter.format(value)}</div>
      ),
    },
    {
      name: "Matching (%)",
      key: "matchingPercentage",
      formatter: ({ value }) => (
        <div className="text-center">{percentFormatter.format(value)}</div>
      ),
    },
    {
      name: "Matching Amount",
      key: "matchingAmount",
      formatter: ({ value }) => (
        <div className="text-center">{currencyFormatter.format(value)}</div>
      ),
    },
  ];

  const rows = items.map((element, index) => (
    <tr key={index}>
      <td>{element.payrollPeriod}</td>
      <td>{element.payPerPeriod}</td>
      <td>{element.contribution}</td>
      <td>{element.matchingLimit}</td>
      <td>{element.matchingPercentage}</td>
      <td>{element.matchingAmount}</td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column) => {
            return <th key={column.key}>{column.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

ContributionsTable.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number,
  limit: PropTypes.number,
};

ContributionsTable.defaultProps = {
  items: [],
  page: 0,
  limit: 10,
};
