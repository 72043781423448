const oauth = {
  // Domain name
  domain: "flexi-id.auth.us-east-1.amazoncognito.com",

  // Authorized scopes
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
  ],

  // Callback URL
  redirectSignIn: process.env.REACT_APP_HOST,

  // Sign out URL
  redirectSignOut: process.env.REACT_APP_HOST,

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: "code",

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },
};

//OPTIONAL - Configuration for cookie storage
const cookieStorage = {
  // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  domain: process.env.REACT_APP_HOST,

  // OPTIONAL - Cookie path
  path: "/",

  // OPTIONAL - Cookie expiration in days
  expires: 365,

  // OPTIONAL - Cookie secure flag
  secure: true,
};

const Auth = {
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: "us-east-1:8ff0f0aa-8428-421e-aa2e-01a3ab6a2435",

  // REQUIRED - Amazon Cognito Region
  region: "us-east-1",

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "us-east-1_jgQWISM7T",

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: "i7p6pie3vc41cuj38oo06j0oe",

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  cookieStorage: false ? cookieStorage : null,
  oauth: oauth,
};

export default Auth;
