import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button } from "@mantine/core";

import config from "config";

const loginUrl = `https://${config.Auth.oauth.domain}/login?client_id=${
  config.Auth.userPoolWebClientId
}&response_type=${
  config.Auth.oauth.responseType
}&scope=${config.Auth.oauth.scope.join("+")}&redirect_uri=${
  config.Auth.oauth.redirectSignIn
}`;

export const SignInButton = ({ className }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        window.open(loginUrl);
      }}
      className={className}
    >
      Sign In
    </Button>
  );
};

SignInButton.propTypes = {
  className: PropTypes.string,
};
