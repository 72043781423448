import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AppLayout } from "app/AppLayout";
import { AuthProvider } from "auth/AuthContext";

import { MantineProvider } from "@mantine/core";

import { PublicHomePage } from "modules/home/pages/PublicHomePage";
import { SignOutPage } from "modules/users/pages/SignOutPage";

import DashboardPage from "modules/dashboard/pages/DashboardPage";
import ContributionsPlanPage from "modules/retirement/pages/ContributionsPlanPage";
// import IntuitPage from "modules/intuit/pages/IntuitPage";

import MarketplacePage from "modules/marketplace/pages/MarketplacePage";

import { useMantineTheme } from "@mantine/core";

function App() {
  const theme = useMantineTheme();

  return (
    <MantineProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<div />} />
            <Route index element={<PublicHomePage />} />
            <Route path="signout" element={<SignOutPage />} />

            <Route path="app/*" element={<AppLayout />}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route
                path="contributions-plan"
                element={<ContributionsPlanPage />}
              />
              {/*
                <Route path="intuit" element={<IntuitPage />} />
                */}
              <Route path="marketplace" element={<MarketplacePage />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </MantineProvider>
  );
}

export default App;
