import { combineReducers } from 'redux';

const solutions = (state={}, action) => {
    return state
}

const rootReducer = combineReducers({
    solutions
});

export default rootReducer;
