class ContributionItem {
  contributionLimitIsReached = false;
  payrollPeriod = "";
  grossPay = 30000;
  periodsCount = 12;
  contributionPercentage = 5;
  matchingLimit = 8;
  matchingPercentage = 50;

  constructor(props) {
    this.contributionLimitIsReached = props.contributionLimitIsReached;
    this.payrollPeriod = props.payrollPeriod;
    this.grossPay = props.grossPay;
    this.contributionPercentage = props.contributionPercentage;
    this.matchingLimit = props.matchingLimit;
    this.matchingPercentage = props.matchingPercentage;
  }

  getItem() {
    return {
      payrollPeriod: this.payrollPeriod,
      payPerPeriod: this.getPayPerPeriod(),
      contribution: this.getContributionAmount(),
      matchingLimit: this.getMatchingLimit(),
      matchingPercentage: this.getMatchingPercentage(),
      matchingAmount: this.getMatchingAmount(),
    };
  }

  getPayPerPeriod() {
    return this.grossPay / this.periodsCount;
  }

  getContributionAmount() {
    if (this.contributionLimitIsReached) {
      return 0;
    }
    return this.getPayPerPeriod() * this.getContributionPercentage();
  }

  getContributionPercentage() {
    return this.contributionPercentage / 100;
  }

  getMatchingLimit() {
    return this.matchingLimit / 100;
  }

  getMatchingPercentage() {
    return this.matchingPercentage / 100;
  }

  getMatchingAmount() {
    if (this.contributionLimitIsReached) {
      return 0;
    }
    return (
      this.getPayPerPeriod() *
      this.getMatchingLimit() *
      this.getMatchingPercentage()
    );
  }
}

export default ContributionItem;
