import React from "react";
import { Auth } from "aws-amplify";
import { Outlet } from "react-router-dom";

import { AppHeader } from "components/page/AppHeader";
import { NavbarSimple } from "components/page/NavbarSimple";
import { FooterSimple } from "components/page/FooterSimple";
import { AppShell, Navbar } from "@mantine/core";

import links from "config/footer-links";

export const AppLayout = () => {
  return (
    <AppShell
      padding="md"
      navbar={<NavbarSimple />}
      header={<AppHeader />}
      footer={<FooterSimple links={links} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default AppLayout;
